import axios from 'axios';


//URL fo API request
export const Axios = axios.create({
  // baseURL: 'https://idelivery.inabia.com/api', //Staging
baseURL: 'https://api.amacvoters.org', //Amac

  
  headers: {
    'Content-type': 'application/json'
  }
});

//Project Name
export const projectName = {
  Path: 'AMAC voters',  //Admin
}


export const S3bucket = {}


//Check for file statuses
export const AxiosNetwork = axios.create({
  baseURL: 'https://inabia.com/inabia-ai-crobjob',
  headers: {
    'Content-type': 'application/json'
  }
});

//Initialize user login parameters for API calls
Axios.interceptors.request.use((config) => {
  const userId = localStorage.getItem('userId');

  const token = localStorage.getItem('token');
  const times = Date.now()
  if (userId && token) {
    config.params = {
      userId,
      token,
      times,
      ...config.params
    };
  } else if (!userId && !token) {
    const token = 'sdddwddwdwdwwd';
    config.params = {
      times,
      ...config.params
    };
  }
  return config;
});

// Api Calls
export enum API {
  NetworkError = '/ediscoveryAPI.php',
  forgotPassAWS = '/forgot',
  forgotPassCodeAWS = '/confirmforgot',
  loginAWS = '/login',
  registerAWS = '/signup',
  AWSauth = '/AWSauth',
  signupAWSCode = '/verify',
  changePassAWS = 'changePassAWS',
  S3UploadPath= 'S3UploadPath',
  VoterData = '/getData',
  ResendVerificationCode = "resendCode",
  updateEthnicity = '/update',
  search = '/search',
  getAnalytics1 = '/getAnalytics1',
  getAnalytics2 = '/getAnalytics2',
  getUserData = '/getUserData',
  updateUserStatus = '/updateUserStatus',
  updateUserVersion = '/updateUserVersion',
  getCountries = '/getCountries',
  getDataSh = '/getDataSh',
  signupNew = '/signupNew',
  deleteUser ='/deleteUser',
  disableUser = '/disableUser',
  searchDynamoDB = '/searchDynamoDB',
  searchClickHouse = '/searchClickHouse',
  DownloadAccess = '/DownloadAccess',
  searchPostgres = '/searchPostgres',
  updateEthnicityClickhouse = '/updateEthnicityClickhouse',
  ReligionClassifications = '/ReligionClassifications',
  updateReligionClickhouse = '/updateReligionClickhouse',
  userActivityLogs = '/userActivityLogs',
  disableUserAdmin = '/disableUserAdmin',
  signupNewAdmin = '/signupNewAdmin',
  userActivityLogs1M = '/userActivityLogs1M',
  searchClickHouse2 = '/searchClickHouse',
  addOwnership = '/addOwnership',
  getOwnership = '/getOwnership',
  deleteOwnership  = '/deleteOwnership',
  updateOwnershipClickHouse = '/updateOwnershipClickHouse',
  votingData = "votingData",
  getCities = "getCities",
  getAnalytics3 = "getAnalytics3",
  downloadMuslimVoters = "downloadMuslimVoters",
  getAugStatus = "getAugStatus",
  getBallotStatus = 'getBallotStatus',
  tshirtCommunities = 'tshirtCommunities',
  getAnalyticsCountryWise = 'getAnalyticsCountryWise'
}

export default Axios;
